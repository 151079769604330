.items{
    border-radius: 8px;
}

.item-header{
    display: flex;
    flex-direction: row;
    background-color: lightgrey;
    font-size: 14pt;
    font-weight: bold;
    gap: 5px;
    align-items: center;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: gray;
}    

.item-header:hover{
    background-color: #003377;
    color: white;
}

.item-header button {
    background-color: inherit;
    border-width: 0;
    color: inherit;
}

.hidden {
    display: none;
}