.categoria_background{
    background-color: #d9d9d9;
}
.cabecera_background{
    background-color: #003377;
}
.color_rojo{
    background-color: #e30613;
}
.color_text{
    color: #003377;
}
.input_background{
    background-color: #d9d9d9;
}