.loader {
    animation: rotate 2s infinite;
    display: block;
    height: 250px;
    width: 250px;
    margin-bottom: 50px;
    --color-1 : #ED1C24;
    --color-2: #003377;
  }

  .loader:before,
  .loader:after {
    border-radius: 50%;
    content: "";
    display: block;
    height: 100px;
    width: 100px;
  }
  .loader:before {
    animation: ball1 1s infinite;
    background-color: var(--color-1);
    box-shadow: 150px 0 0 var(--color-2);
    margin-bottom: 50px;
  }
  .loader:after {
    animation: ball2 1s infinite;
    background-color: var(--color-2);
    box-shadow: 150px 0 0 var(--color-1);
  }

  @keyframes rotate {
    0% { transform: rotate(0deg) scale(0.8) }
    50% { transform: rotate(360deg) scale(1.2) }
    100% { transform: rotate(720deg) scale(0.8) }
  }

  @keyframes ball1 {
    0% {
      box-shadow: 150px 0 0 var(--color-2);
    }
    50% {
      box-shadow: 0 0 0 var(--color-2);
      margin-bottom: 0;
      transform: translate(75px, 75px);
    }
    100% {
      box-shadow: 150px 0 0 var(--color-2);
      margin-bottom: 50px;
    }
  }

  @keyframes ball2 {
    0% {
      box-shadow: 150px 0 0 var(--color-1);
    }
    50% {
      box-shadow: 0 0 0 var(--color-1);
      margin-top: -100px;
      transform: translate(75px, 75px);
    }
    100% {
      box-shadow: 150px 0 0 var(--color-1);
      margin-top: 0;
    }
  }
  